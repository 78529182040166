import {Subscription} from '../types/Stripe'
import {VALID_STATUS_SUBSCRIPTIONS} from '../constants/StripeConstants'

export const findTrialSubscription = (subscriptions: Subscription[]): Subscription | undefined =>
	subscriptions.filter(isTrialSubscription)?.[0]

export const isTrialSubscription = (subscription: Subscription): boolean =>
	subscription.status === 'trialing'

export const hasOrganizationValidSubscription = (subscriptions: Subscription[]): boolean =>
	subscriptions.some(isValidSubscription)

export const isValidSubscription = (subscription: Subscription): boolean =>
	VALID_STATUS_SUBSCRIPTIONS.includes(subscription.status)

export const getCurrentSubscription = (subscriptions: Subscription[]): Subscription | undefined =>
	subscriptions.find(subscription => (subscription.current_period_end * 1000) > new Date().getTime())

export const isOrganizationNotPaidAfterTrial = (subscription: Subscription): boolean =>
	subscription.status === 'paused'