import {Outlet} from 'react-router-dom'
import {UserContextProvider} from '../../../context/UserContext'
import React from 'react'
import {PaymentContextProvider} from '../../../context/PaymentContext'
import {StripeSubscriptionsContextProvider} from '../../../context/StripeSubscriptionsContext'

export const PaymentWrapper = () => (
    <UserContextProvider>
        <StripeSubscriptionsContextProvider>
            <PaymentContextProvider>
                <Outlet/>
            </PaymentContextProvider>
        </StripeSubscriptionsContextProvider>
    </UserContextProvider>
)