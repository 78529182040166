import {Alert} from '@mui/material'
import './NoBudgetUserAlert.scss'
import {useStripeSubscriptionsContext} from '../../context/StripeSubscriptionsContext'
import {formatDateStringToLocaleDate} from '../../helpers/DateHelpers'
import {useUserInfoContext} from '../../context/UserInfoContext'

export const WillExpireLicenseAlert = () => {

	const {currentSubscription} = useStripeSubscriptionsContext()
	const {userInfo} = useUserInfoContext()

	const isCurrentlySubscriptionCancelled = currentSubscription?.cancellation_details?.reason === 'cancellation_requested'
	const isAdmin = !!userInfo?.isAdmin

	if (!isAdmin || !currentSubscription || !isCurrentlySubscriptionCancelled) return <></>

	return <Alert severity='error' className='willExpireLicenseUserBanner'>
		You have cancelled your subscription to Narus. Cancellation will be effective on {formatDateStringToLocaleDate(currentSubscription.current_period_end * 1000)}.
	</Alert>
}