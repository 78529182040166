import {Dialog, DialogContent, DialogTitle} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import './PlansDialog.scss'
import {PricingContent} from '../../../license/pricingTable/PricingContent'

interface PlansDialogProps {
    open: boolean
    onClose: () => void
}

export const PlansDialog = ({open, onClose}: PlansDialogProps) => {

    return <Dialog onClose={onClose} open={open} className='plansDialogContainer'>
        <DialogTitle className='plansDialogTitle'>
            <span>Change plan</span>
            <CloseIcon className='plansDialogCloseIcon' onClick={onClose}/>
        </DialogTitle>
        <DialogContent className='plansDialogContent'>
            <PricingContent isChangePlan={true}/>
        </DialogContent>
    </Dialog>
}