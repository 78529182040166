import {useEffect} from 'react'
import {PageView} from '../../../service/SegmentService'
import {PricingContent} from './PricingContent'
import {Box} from '@mui/material'
import './PricingTable.scss'

export const PricingTable = () => {

    useEffect(() => {
        PageView('Pricing')
    })

    return <Box className='pricingTableContainer'>
        <PricingContent isChangePlan={false}/>
    </Box>
}