import {createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useAuth, useUser} from '@clerk/clerk-react'
import Spinner from '../components/spinner/Spinner'
import {useErrorBoundary} from 'react-error-boundary'
import {jwtDecode} from 'jwt-decode'
import {AppGridWrapper} from '../components/appGridWrapper/AppGridWrapper'
import {nowInSeconds} from '../utils/dateUtils'

interface UserContextValue {
	token: string
	selectedTab: number
	setSelectedTab: Dispatch<SetStateAction<number>>
	initialAnalyticTracked: boolean
	setInitialAnalyticTracked: Dispatch<SetStateAction<boolean>>
}

const DEFAULT_USER_CONTEXT: UserContextValue = {
	token: '',
	selectedTab: 0,
	setSelectedTab: () => {},
	initialAnalyticTracked: false,
	setInitialAnalyticTracked: () => {}
}

export const UserContext = createContext<UserContextValue>(DEFAULT_USER_CONTEXT)

export const useUserContext = () => useContext(UserContext)

export const UserContextProvider: FC<PropsWithChildren> = ({
	children
})=> {
	const [selectedTab, setSelectedTab] = useState(0)
	const [token, setToken] = useState('')
	const [initialAnalyticTracked, setInitialAnalyticTracked] = useState(false)

	const { getToken } = useAuth()
	const { user } = useUser()

    const { showBoundary } = useErrorBoundary()

	const storeToken = useCallback(() => {
		return getToken({template: process.env.REACT_APP_CLERK_TEMPLATE_NAME})
			.then(token => {
				const newToken = token ?? ''
				setToken(newToken)
				return newToken
			})
			.catch(showBoundary)
	}, [getToken, showBoundary])

	const renewTokenIfExpired = useCallback(() => {
		const decoded = jwtDecode(token)
		const now = nowInSeconds()
		const isExpiredOrNearlyToExpire = decoded.exp && (decoded.exp - 10) < now
		if (token && isExpiredOrNearlyToExpire) return storeToken()
	}, [storeToken, token])

	useEffect(() => {
		storeToken()
		const intervalId = setInterval(renewTokenIfExpired, 10_000)
		return () => clearInterval(intervalId)
	}, [renewTokenIfExpired, storeToken, user?.publicMetadata])

	const value: UserContextValue = useMemo(() => ({
		token,
		selectedTab,
		setSelectedTab,
		initialAnalyticTracked,
		setInitialAnalyticTracked
	}), [token, selectedTab, initialAnalyticTracked])
	
	return <UserContext.Provider value={value}>
		{token ? children : <AppGridWrapper><Spinner/></AppGridWrapper>}
	</UserContext.Provider>
}
