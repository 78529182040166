import React, {useEffect, useState} from 'react'
import {Elements} from '@stripe/react-stripe-js'
import {Box, Divider, Grid, Link, Typography} from '@mui/material'
import {NarusLogo} from '../../icons/NarusLogo'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {UserButton, useUser} from '@clerk/clerk-react'
import './Payment.scss'
import {PaymentDetails} from './PaymentDetails'
import {EditSeatsModal} from './EditSeatsModal'
import {TrackActionEvent} from '../../../service/SegmentService'
import {usePaymentContext} from '../../../context/PaymentContext'
import {useNavigate} from 'react-router-dom'
import {getPaymentPrice} from '../../../utils/licenseUtils'
import Spinner from '../../spinner/Spinner'
import {CustomerDetails} from './CustomerDetails'
import {formatAmount} from '../../../utils/currencyUtils'
import {STRIPE_PUBLISHABLE_KEY, PRICES_INFO_MAP} from '../../../constants/StripeConstants'

interface PaymentProps {
	isChangePlan?: boolean
}

export const Payment = ({isChangePlan}: PaymentProps) => {
	const navigate = useNavigate()

	const {user} = useUser()
	const {planId, seats, selectedPlan, periodSelected, stripe, clientSecret, taxes, setTaxes, taxesLoading, amount} = usePaymentContext()
	const [seatsModalOpen, setSeatsModalOpen] = useState(false)

	useEffect(() => {
		if (!STRIPE_PUBLISHABLE_KEY || !planId || !selectedPlan) return navigate('/pricing')
	}, [planId, selectedPlan, navigate])

	const onCloseSeatsModal = () => {
		TrackActionEvent('Edit seats modal', user?.id, {action: 'close'})
		setSeatsModalOpen(false)
	}

	const onGoBack = () => {
		setTaxes(undefined)
		navigate(-1)
	}

	const price = getPaymentPrice(seats, selectedPlan!, periodSelected)

	if (!STRIPE_PUBLISHABLE_KEY || !planId || !selectedPlan) return <></>

	return <Grid container className='paymentContainer'>
		<Grid item xs={6} className='summaryContainer'>
			<NarusLogo height={38} width={38}/>
			<Box className='titleContainer'>
				<Link onClick={onGoBack} className='goBackIconLink'><ArrowBackIcon/></Link>
				<Typography variant='h3'>Summary</Typography>
			</Box>
			<Box className='purchaseDetailWrapper'>
				<Typography className='subtitle'>Subscribe to {PRICES_INFO_MAP[selectedPlan!!].name} plan</Typography>
				<Box className='totalPriceContainer'>
					<Typography className='totalPrice'>{price}</Typography>
					<Typography className='period'>Per {periodSelected === 'annual' ? 'year' : 'month'}</Typography>
				</Box>
				<Box className='purchaseDetailsContainer'>
					<Box className='purchaseRow'>
						<Typography>Plan Pro billed {periodSelected === 'annual' ? 'annually' : 'monthly'}</Typography>
						<Typography>{price}</Typography>
					</Box>
					<Box className='seatsButton'>Seats {seats}</Box>
					<Divider className='purchaseDivider'/>
					<Box className='purchaseRow'>
						<Typography>Subtotal</Typography>
						<Typography>{price}</Typography>
					</Box>
					<Box className='purchaseRow'>
						<Typography>VAT {taxes ? `(${taxes.tax_breakdown[0].tax_rate_details.percentage_decimal}% inclusive)` : ''}</Typography>
						{taxesLoading ? <Spinner/> : <Typography>{(taxes ? formatAmount(taxes.tax_amount_exclusive / 100) : 'Waiting for address data')}</Typography>}
					</Box>
					{/*<Divider className='purchaseDivider'/>*/}
					{/*<Typography>Discount code</Typography>*/}
					{/*<TextField className='discount' fullWidth placeholder='Add a promotion code'/>*/}
					<Divider className='purchaseDivider'/>
					<Box className='purchaseRow'>
						<Typography>Total due today</Typography>
						{taxesLoading ? <Spinner/> : <Typography>{(taxes ? formatAmount(taxes.amount_total / 100) : 'Waiting for address data')}</Typography>}
					</Box>
				</Box>
			</Box>
		</Grid>
		<Grid item xs={6} className='cardContainer'>
			<Box className='userButtonContainer'>
				<UserButton showName={false}/>
			</Box>
			<Box className='cardDetailsWrapper'>
				{stripe && <>
					{!taxes && <Elements stripe={stripe} options={{mode: 'subscription', amount, currency: 'usd'}}><CustomerDetails/></Elements>}
					{taxes && clientSecret && <Elements stripe={stripe} options={{clientSecret}}> <PaymentDetails stripe={stripe} isChangePlan={isChangePlan}/></Elements>}
                    <EditSeatsModal seatsModalOpen={seatsModalOpen} onModalClose={onCloseSeatsModal}/>
                </>}
				{!stripe && <Box className='spinnerContainer'><Spinner/></Box>}
			</Box>
		</Grid>
	</Grid>
}