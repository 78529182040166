import {Outlet} from 'react-router-dom'
import {AccountCreationContextProvider} from '../../context/AccountCreationContext'
import {UserOrganizationContextProvider} from '../../context/UserOrganizationContext'
import {TrialContextProvider} from '../../context/TrialContext'
import {UserContextProvider} from '../../context/UserContext'

export const AccountCreationWrapper = () => (
    <UserContextProvider>
        <UserOrganizationContextProvider>
            <TrialContextProvider>
                <AccountCreationContextProvider>
                    <Outlet/>
                </AccountCreationContextProvider>
            </TrialContextProvider>
        </UserOrganizationContextProvider>
    </UserContextProvider>
)
