import {createContext, ReactNode, useContext} from 'react'
import {useStripeSubscriptions} from '../hooks/useStripeSubscriptions'
import {Subscription} from '../types/Stripe'

interface StripeSubscriptionsContextProviderProps {
    children: ReactNode
}

export interface StripeSubscriptionsContextValue {
    subscriptions: Subscription[]
    currentSubscription?: Subscription
    isActiveSubscription: boolean
    trialDaysLeft: number | undefined
    isTrial: boolean
    isPausedSubscription: boolean
    cancelCurrentSubscription: (subscriptionId: string) => Promise<void>
    updateSubscriptionSeats: (seats: number) => Promise<void>
}

const DEFAULT_SUBSCRIPTIONS_CONTEXT: StripeSubscriptionsContextValue = {
    subscriptions: [],
    isActiveSubscription: false,
    trialDaysLeft: undefined,
    isTrial: false,
    isPausedSubscription: false,
    cancelCurrentSubscription: () => Promise.resolve(),
    updateSubscriptionSeats: (seats: number) => Promise.resolve()
}

export const StripeSubscriptionsContext = createContext<StripeSubscriptionsContextValue>(DEFAULT_SUBSCRIPTIONS_CONTEXT)

export const useStripeSubscriptionsContext = () => useContext(StripeSubscriptionsContext)

export const StripeSubscriptionsContextProvider = ({children}: StripeSubscriptionsContextProviderProps) => {
    const value = useStripeSubscriptions()

    return <StripeSubscriptionsContext.Provider value={value}>{children}</StripeSubscriptionsContext.Provider>
}