import {Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react'
import {Prompt, PromptProperties, UserPromptTemplate} from "../types/Prompt"
import {executePrompt, storePrompt} from '../service/persistenceService'
import {PromptExecutionResult} from '../types/PromptExecutionResult'
import {AIModelID} from '../types/AiModel'
import {usePromptsContext} from '../context/PromptsContext'
import {useUserContext} from '../context/UserContext'
import {PromptChatDetail} from '../types/PromptChatDetail'
import {AmazonQResponseSettings} from '../types/AmazonQResponseSettings'
import {PromptCreationContextValue} from '../context/PromptCreationContext'
import {useChatMessagesContext} from '../context/ChatMessagesContext'
import {createPromptObject} from '../utils/promptUtils'
import {WithOptional} from '../types/Generic'
import {PromptExecutionParams} from '../types/PromptExecutionParams'
import {useFilesContext} from '../context/FilesContext'
import {FileId} from '../types/File'

export const usePromptCreation = (): PromptCreationContextValue => {
	const {token} = useUserContext()

	const {setPrompts, setRecentlyUsedPrompts, setUserPrompts} = usePromptsContext()
	const {chatId} = useChatMessagesContext()
	const {isRagQuery, files} = useFilesContext()

	const fileIds: FileId[] = files.map(file => file.metadata.id)

	const [loading, setLoading] = useState(false)
	const [aiPromptLoading, setAiPromptLoading] = useState(false)
	const [aiOutputLoading, setAiOutputLoading] = useState(false)
	const [aiPromptCompilationLoading, setAiPromptCompilationLoading] = useState(false)
	const [userPrompt, setUserPrompt] = useState('')
	const [audience, setAudience] = useState('')
	const [tone, setTone] = useState('')
	const [format, setFormat] = useState('')
	const [modelId, setModelId] = useState<AIModelID>()
	const [modelIds, setModelIds] = useState<AIModelID[]>([])
	const [aiPrompt, setAiPrompt] = useState('')
	const [aiOutput, setAiOutput] = useState('')
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [language, setLanguage] = useState('')
	const [frequencyPenalty, setFrequencyPenalty] = useState('0')
	const [temperature, setTemperature] = useState('0.75')
	const [promptLabels, setPromptLabels] = useState<string[]>([])
	const [promptTeams, setPromptTeams] = useState<string[]>([])
	const [isPublic, setIsPublic] = useState(false)
	const [originalPrompt, setOriginalPrompt] = useState<Prompt>()
	const [unsavedChanges, setUnsavedChanges] = useState(false)
	const [promptChats, setPromptChats] = useState<PromptChatDetail[]>([])
	const [promptId, setPromptId] = useState<string>()
	const [amazonQResponseSettings, setAmazonQResponseSettings] = useState<AmazonQResponseSettings>()

	const setValueHandler = useCallback((set: Dispatch<SetStateAction<any>>, value: any, fieldName: PromptProperties) => {
		set(value)
		if (['labels', 'teams'].includes(fieldName)) setUnsavedChanges(originalPrompt !== undefined && originalPrompt[fieldName].some((arrayValue: string, index: number) => arrayValue !== value[index]))
		else setUnsavedChanges(originalPrompt !== undefined && originalPrompt[fieldName] !== value)
	}, [originalPrompt])

	const createPrompt = useCallback((promptTemplateData: UserPromptTemplate): Promise<Prompt> => {
		setLoading(true)
		const prompt = createPromptObject(promptTemplateData)
		return storePrompt(prompt, token, promptTemplateData.tempPromptId ?? '')
			.then(createdPrompt => {
				setPromptId(createdPrompt.id)
				setOriginalPrompt(createdPrompt)
				setPrompts(previousPrompts => [...previousPrompts, createdPrompt])
				setRecentlyUsedPrompts(previousPrompts => [...previousPrompts, createdPrompt])
				setUserPrompts(prev => [...prev, createdPrompt])
				return createdPrompt
			})
			.finally(() => setLoading(false))
	}, [token, setPrompts, setRecentlyUsedPrompts, setPromptId, setUserPrompts])

	const getAiGeneratedPrompt = useCallback((promptTemplate: UserPromptTemplate, signal: AbortSignal, promptId?: string): Promise<PromptExecutionResult> => {
		setAiPromptLoading(true)
		promptTemplate.conversationId = amazonQResponseSettings?.conversationId
		promptTemplate.systemMessageId = amazonQResponseSettings?.systemMessageId
		promptTemplate.chatId = chatId

		const promptExecutionParams: PromptExecutionParams = {
			promptTemplate,
			userToken: token,
			signal,
			promptId,
			setPromptChats,
			sender: 'user',
			setAmazonQConversationId: setAmazonQResponseSettings,
			optimizePrompt: true,
			isChatSummary: false,
			isRegeneratedMessage: false
		}
		return executePrompt(promptExecutionParams)
			.then(promptExecutionResult => promptExecutionResult)
			.finally(() => setAiPromptLoading(false))
	}, [token, amazonQResponseSettings, chatId])

	const getAiOutput = useCallback((promptTemplate: UserPromptTemplate, signal: AbortSignal, isRegeneratedMessage: boolean, promptId?: string, previousMessages?: string[]): Promise<PromptExecutionResult> => {
		setAiOutputLoading(true)
		promptTemplate.conversationId = amazonQResponseSettings?.conversationId
		promptTemplate.systemMessageId = amazonQResponseSettings?.systemMessageId
		promptTemplate.chatId = chatId
		const promptExecutionParams: PromptExecutionParams = {
			promptTemplate,
			userToken: token,
			signal,
			promptId,
			setPromptChats,
			sender: 'bot',
			setAmazonQConversationId: setAmazonQResponseSettings,
			optimizePrompt: false,
			isChatSummary: false,
			isRegeneratedMessage,
			previousMessages,
			isRagQuery,
			fileIds
		}
		return executePrompt(promptExecutionParams)
			.then(promptExecutionResult => promptExecutionResult)
			.finally(() => {
				setAiOutputLoading(false)
			})
	}, [token, amazonQResponseSettings, chatId, isRagQuery, fileIds])

	const getPromptCompilation = useCallback(async (promptTemplate: UserPromptTemplate, signal: AbortSignal, userMessages: string[], promptId?: string): Promise<PromptExecutionResult | void> => {
		if (userMessages.length === 1) {
			setPromptChats((previousValue: PromptChatDetail[]): PromptChatDetail[] => {
				const chatIndex = previousValue.findIndex(previousChat => previousChat.modelId === promptTemplate.modelId)
				if (chatIndex === -1) return previousValue

				const updatedChat: PromptChatDetail = {
					...previousValue[chatIndex],
					compilationSummary: {summary: userMessages[0], isChatUpdated: false}
				}
				return [...previousValue.slice(0, chatIndex), updatedChat, ...previousValue.slice(chatIndex + 1)]
			})
			return
		}
		setAiPromptCompilationLoading(true)
		promptTemplate.conversationId = amazonQResponseSettings?.conversationId
		promptTemplate.systemMessageId = amazonQResponseSettings?.systemMessageId
		promptTemplate.chatId = chatId
		const promptExecutionParams: PromptExecutionParams = {
			promptTemplate,
			userToken: token,
			signal,
			promptId,
			setPromptChats,
			sender: 'bot',
			setAmazonQConversationId: setAmazonQResponseSettings,
			optimizePrompt: false,
			isChatSummary: true,
			isRegeneratedMessage: false,
			userMessages
		}
		return executePrompt(promptExecutionParams)
			.then(promptExecutionResult => promptExecutionResult)
			.finally(() => setAiPromptCompilationLoading(false))
	}, [token, amazonQResponseSettings, chatId, setPromptChats])

	const getPromptTemplate = useCallback((): WithOptional<UserPromptTemplate, 'modelId'> => ({
		modelId,
		description,
		title,
		userPrompt,
		aiPrompt,
		audience,
		tone,
		format,
		frequencyPenalty,
		language,
		temperature,
		labels: promptLabels,
		teams: promptTeams,
		isPublic,
	}), [modelId, description, title, userPrompt, aiPrompt, audience, tone, format, frequencyPenalty, language, temperature, promptLabels, promptTeams, isPublic])

	const clearPromptFields = useCallback(() => {
		setAiOutputLoading(false)
		setAiPromptLoading(false)
		setOriginalPrompt(undefined)
		setUserPrompt('')
		setAudience('')
		setTone('')
		setFormat('')
		setModelId(undefined)
		setAiPrompt('')
		setAiOutput('')
		setTitle('')
		setDescription('')
		setLanguage('')
		setFrequencyPenalty('0')
		setTemperature('0.75')
		setPromptLabels([])
		setPromptTeams([])
		setIsPublic(false)
		setPromptId('')
		setAmazonQResponseSettings(undefined)
	}, [])

	const fillPromptFields = useCallback((prompt: Prompt) => {
		setOriginalPrompt(prompt)
		setUserPrompt(prompt.userPrompt)
		setAudience(prompt.audience || '')
		setTone(prompt.tone || '')
		setFormat(prompt.format || '')
		setModelId(prompt.modelId)
		setAiPrompt(prompt.aiPrompt)
		setAiOutput(prompt.aiOutput?.aiOutput || '')
		setTitle(prompt.title)
		setDescription(prompt.description)
		setLanguage(prompt.language || '')
		setFrequencyPenalty(prompt.frequencyPenalty || '0')
		setTemperature(prompt.temperature || '0.75')
		setPromptLabels(prompt.labels || [])
		setPromptTeams(prompt.teams || [])
		setIsPublic(prompt.isPublic ?? false)
		setPromptId(prompt.id)
	}, [])

	const isOptimizedPrompt = useCallback((): boolean =>
			!!format || !!audience || !!tone || language !== '' || frequencyPenalty !== '0' || temperature !== '0.75'
		, [format, audience, tone, language, frequencyPenalty, temperature])

	const clearPromptOptimizationFields = useCallback(() => {
		setAudience('')
		setTone('')
		setFormat('')
		setLanguage('')
		setFrequencyPenalty('0')
		setTemperature('0.75')
	}, [])

	return useMemo(() => ({
		loading, setLoading,
		aiPromptLoading, setAiPromptLoading,
		aiOutputLoading, setAiOutputLoading,
		aiPromptCompilationLoading, setAiPromptCompilationLoading,
		userPrompt, setUserPrompt,
		audience, setAudience,
		tone, setTone,
		format, setFormat,
		modelId, setModelId,
		modelIds, setModelIds,
		aiPrompt, setAiPrompt,
		aiOutput, setAiOutput,
		title, setTitle,
		description, setDescription,
		language, setLanguage,
		frequencyPenalty, setFrequencyPenalty,
		temperature, setTemperature,
		promptLabels, setPromptLabels,
		promptTeams, setPromptTeams,
		isPublic, setIsPublic,
		unsavedChanges, setUnsavedChanges,
		promptChats, setPromptChats,
		createPrompt,
		getAiGeneratedPrompt,
		getAiOutput,
		getPromptTemplate,
		clearPromptFields,
		fillPromptFields,
		setValueHandler,
		setPromptId,
		getPromptCompilation,
		promptId,
		amazonQConversationId: amazonQResponseSettings?.conversationId,
		amazonQSystemMessageId: amazonQResponseSettings?.systemMessageId,
		isOptimizedPrompt, clearPromptOptimizationFields
	}), [
		loading,
		aiPromptLoading,
		aiOutputLoading,
		aiPromptCompilationLoading,
		userPrompt,
		audience,
		tone,
		format,
		modelId,
		modelIds,
		aiPrompt,
		aiOutput,
		title,
		description,
		language,
		frequencyPenalty,
		temperature,
		promptLabels,
		promptTeams,
		isPublic,
		unsavedChanges,
		promptChats,
		createPrompt,
		getAiGeneratedPrompt,
		getAiOutput,
		getPromptTemplate,
		clearPromptFields,
		fillPromptFields,
		setValueHandler,
		setPromptId,
		getPromptCompilation,
		promptId,
		amazonQResponseSettings?.conversationId,
		amazonQResponseSettings?.systemMessageId,
		isOptimizedPrompt, clearPromptOptimizationFields
	])
}


