import {FC, PropsWithChildren} from 'react'
import {NoBudgetUserAlert} from './NoBudgetUserAlert'
import {WillExpireLicenseAlert} from './WillExpireLicenseAlert'

export const UserAlerts: FC<PropsWithChildren> = ({
    children
}) => {
    return <>
        <WillExpireLicenseAlert/>
        <NoBudgetUserAlert/>
        {children}
    </>
}