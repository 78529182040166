import {Dialog, DialogContent, DialogTitle, DialogActions, Button, Box} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import './PlansDialog.scss'
import {ChangePlanConfirmationDialogContent} from './ChangePlanConfirmationDialogContent'
import {Plan} from '../../../../types/Stripe'
import {isUpgradedPlan} from '../../../../utils/licenseUtils'
import {usePaymentContext} from '../../../../context/PaymentContext'
import {capitalizeFirstLetter} from '../../../../utils/textUtils'
import {useNavigate} from 'react-router-dom'

interface PlansConfirmationDialogProps {
	open: boolean
	onClose: () => void
	plan: Plan
}

export const PlansConfirmationDialog = ({open, onClose, plan}: PlansConfirmationDialogProps) => {

	const {selectedPlan, setOpenChangePlanConfirmationDialog} = usePaymentContext()
	const navigate = useNavigate()
	const typeChange = isUpgradedPlan(plan, selectedPlan!) ? 'upgrade' : 'downgrade'

	const changePlanPaymentHandler = () => {
		setOpenChangePlanConfirmationDialog(false)
		navigate('/admin/configuration/payment')
	}

	return <Dialog onClose={onClose} open={open} className='plansDialogContainer'>
		<DialogTitle className='plansDialogTitle'>
			<span>{isUpgradedPlan(plan, selectedPlan!) ? 'Upgrade' : 'Downgrade'} plan</span>
			<CloseIcon className='plansDialogCloseIcon' onClick={onClose}/>
		</DialogTitle>
		<DialogContent className='plansDialogContent'>
			<ChangePlanConfirmationDialogContent updatedPlan={selectedPlan!} planTypeChange={typeChange}/>
		</DialogContent>
		<DialogActions>
			<Box className='changePlanConfirmationButtonsContainer'>
				<Button onClick={onClose} variant='outlined' className='keepPlanConfirmationButton'>Keep my plan</Button>
				<Button variant='contained' className='changePlanConfirmationButton' onClick={changePlanPaymentHandler}>{capitalizeFirstLetter(typeChange)} Plan</Button>
			</Box>
		</DialogActions>
	</Dialog>
}