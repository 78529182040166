import {internalSegmentId, externalSegmentId} from '../constants'
import type {UserResource} from '@clerk/types'
import { NARUS_ANALYTICS_APP_NAME } from '../constants/StripeConstants'
const {version} = require('../../package.json')

declare global {
    interface Window {
        internalAnalytics: any
        externalAnalytics: any
    }
}

interface UserAnalyticData {
    userId: string
    organizationId: string
    currentLanguage: string
    email: string
    fullName: string
    createdAt: Date | string
    lastSignInAt: Date | string
}

const getAnalytics = (external) => external ? window.externalAnalytics : window.internalAnalytics

export const LoadAnalytics = () => {
    getAnalytics(false)?.load(internalSegmentId)
    getAnalytics(true)?.load(externalSegmentId)
}

export const PageView = (category: string, external = false) => {
    getAnalytics(external)?.page(category, undefined, {
        app_name: NARUS_ANALYTICS_APP_NAME
    })
}

export const AnalyticsIdentify = (userData?: UserResource | null, organizationId?: string, external = false) => {
    if (!userData) return
    getAnalytics(external)?.identify(userData.id, buildUserData(userData, organizationId))
}

export const GroupEvent = (userId?: string, traits: any = {}, external = false) => {
    getAnalytics(external)?.group(userId, traits)
}

export const TrackActionEvent = async (eventName: string, userId?: string, extraInfo?: any, external = false) => {
    getAnalytics(external)?.track(eventName, {
        userId,
        version: version || '',
        app_name: NARUS_ANALYTICS_APP_NAME,
        eventName,
        ...extraInfo
    })
}

export const TrackEvent = (props, external = false) => {
    getAnalytics(external)?.track(props['name'], props['data'])
}

const buildUserData = (user?: UserResource, organizationId?: string): UserAnalyticData => ({
    userId: user?.externalId ?? user?.id ?? '',
    organizationId: organizationId ?? '',
    currentLanguage: navigator.language,
    fullName: user?.fullName || '',
    email: user?.emailAddresses[0].emailAddress || '',
    createdAt: user?.createdAt || '',
    lastSignInAt: user?.lastSignInAt || ''
})

export const getHomeTabForAnalytics = (tabValue: number): string => {
    switch (tabValue) {
        case 0:
            return 'my_recent_prompts'
        case 1:
            return 'favourites'
        default:
            return 'search'
    }
}

export const getAdminTabForAnalytics = (tabValue: number): string => {
    switch (tabValue) {
        case 0:
            return 'overview'
        case 1:
            return 'reports'
        default:
            return 'configuration'
    }
}