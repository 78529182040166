import {ClerkProvider} from '@clerk/clerk-react'
import {ErrorBoundary} from 'react-error-boundary'
import {ErrorSection} from './error/ErrorSection'
import {Outlet} from 'react-router'
import {ThemeProvider} from '@mui/material'
import {CUSTOM_THEME} from './themes/CustomThemes'
import {FeedbackContextProvider} from './context/FeedbackContext'

export const AppWrapper = () => {
    if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
        throw new Error('Missing Publishable Key')
    }
    const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

    return (
        <ThemeProvider theme={CUSTOM_THEME}>
            <ClerkProvider publishableKey={clerkPubKey} signInFallbackRedirectUrl={process.env.REACT_APP_FRONTEND_URL} signInForceRedirectUrl={process.env.REACT_APP_FRONTEND_URL}>
                <ErrorBoundary FallbackComponent={ErrorSection}>
                    <FeedbackContextProvider>
                        <Outlet/>
                    </FeedbackContextProvider>
                </ErrorBoundary>
            </ClerkProvider>
        </ThemeProvider>
    )
}
