import {ChangeEvent, FC, useState} from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import {Box, Button, TextField, Typography} from '@mui/material'
import {useAccountCreationContext} from '../../context/AccountCreationContext'
import {areCompanyDomainsValid, getCompanyDomain, isCompanyDomainValid} from '../../utils/accountCreationHelpers'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'

export const AccountCreationFormCompanyDomains: FC = () => {
	const { form, changeFormValue, changeFormValueValid } = useAccountCreationContext()
	const { companyDomains, companyName } = form
	const { user } = useUser()

	const handleAddDomainClicked = () => {
		changeFormValue('companyDomains', [...companyDomains, ''])
		changeFormValueValid('companyDomains', undefined)
		TrackActionEvent('Create Organization', user?.externalId ?? user?.id, {
			company_name: companyName,
			company_domains: companyDomains,
			action: 'add_domain'
		}, true)
	}

	return <Box className='AccountCreationForm_Control'>
		<Typography variant='h4' className='AccountCreationForm_ControlHeader'>
			Connect user base
		</Typography>

		<Typography variant='h6' className='AccountCreationForm_ControlSubheader'>
			Add by domain
		</Typography>
		<Typography variant='subtitle2' className='AccountCreationForm_ControlSubtitle'>
			You can give bulk access by adding an email domain. This allows all users with matching email addresses to access your workspace, eg.@yourcompany.com
		</Typography>
		{companyDomains.map((_, i) => <AccountCreationFormCompanyDomainControl key={i} index={i}/>)}
		<Box>
			<Button onClick={handleAddDomainClicked}>+ Add domain</Button>
		</Box>
	</Box>
}

const AccountCreationFormCompanyDomainControl: FC<{ index: number }> = ({
	index
}) => {
	const { form, error, changeFormValue, changeFormValueValid, changeFormValueError } = useAccountCreationContext()
	const { user } = useUser()
	const [validError, setValidError] = useState<boolean>(false)
	const { companyDomains, companyName } = form
	const domain = companyDomains[index] ?? ''
	const showError = validError || (!!error.companyDomains && !!domain)
	const helperText = showError ? (error.companyDomains || 'Domain has to follow the example @yourcompany.com') : ''

	const getUpdatedCompanyDomains = (value: string): string[] => [...companyDomains.slice(0, index), value, ...companyDomains.slice(index + 1)]

	const handleDeleteClicked = () => {
		const updatedDomains = [...companyDomains.slice(0, index), ...companyDomains.slice(index + 1)]
		changeFormValue('companyDomains', updatedDomains)
		changeFormValueValid('companyDomains', areCompanyDomainsValid(updatedDomains))
		TrackActionEvent('Create Organization', user?.externalId ?? user?.id, {
			company_name: companyName,
			company_domains: updatedDomains,
			action: 'remove_domain'
		}, true)
	}

	const handleInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
		const updatedDomains = getUpdatedCompanyDomains(event.target.value)
		changeFormValue('companyDomains', updatedDomains)
	}

	const handleInputBlurred = () => {
		const value = getCompanyDomain(domain)
		const updatedDomains = getUpdatedCompanyDomains(value)
		setValidError(value ? !isCompanyDomainValid(value) : false)
		changeFormValue('companyDomains', updatedDomains)
		changeFormValueValid('companyDomains', areCompanyDomainsValid(updatedDomains))
	}

	const handleInputFocus = () => {
		setValidError(false)
		changeFormValueError('companyDomains', undefined)
		changeFormValueValid('companyDomains', undefined)
	}

	return (
		<div className='AccountCreationForm_ControlItem'>
			<TextField variant='outlined' 
				label='Company domain' 
				placeholder='@yourcompany.com'
				error={showError} 
				helperText={helperText} 
				value={domain} 
				onFocus={handleInputFocus} 
				onBlur={handleInputBlurred}
				onChange={handleInputChanged}/>
			<DeleteIcon onClick={handleDeleteClicked}/>
		</div>
	)
}