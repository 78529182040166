import {FC, PropsWithChildren} from 'react'
import {UsersAccessContextProvider} from '../context/UsersAccessContext'
import {StatisticsContextProvider} from '../context/StatisticsContext'
import {AuditingLogsContextProvider} from '../context/AuditingLogsContext'
import {OrganizationContextProvider} from '../context/OrganizationContext'
import {AdminEmailsContextProvider} from '../context/AdminEmailsContext'
import {WarningsContextProvider} from '../context/WarningsContext'
import {GroupsContextProvider} from '../context/GroupsContext'
import {AdminNavigationContextProvider} from '../context/AdminNavigationContextProvider'
import {DataGridContextProvider} from '../context/DataGridContext'
import {BudgetsContextProvider} from '../context/BudgetContext'
import {useUserInfoContext} from '../context/UserInfoContext'
import {PaymentContextProvider} from '../context/PaymentContext'

export const AdminProviders: FC<PropsWithChildren> = ({
                                                          children
                                                      }) => {
    const {userInfo} = useUserInfoContext()
    const isAdmin = userInfo?.isAdmin

    return isAdmin ? (
        <UsersAccessContextProvider>
            <StatisticsContextProvider>
                <AuditingLogsContextProvider>
                    <OrganizationContextProvider>
                        <AdminEmailsContextProvider>
                            <WarningsContextProvider>
                                <GroupsContextProvider>
                                    <AdminNavigationContextProvider>
                                        <DataGridContextProvider>
                                            <BudgetsContextProvider>
                                                <PaymentContextProvider>
                                                    {children}
                                                </PaymentContextProvider>
                                            </BudgetsContextProvider>
                                        </DataGridContextProvider>
                                    </AdminNavigationContextProvider>
                                </GroupsContextProvider>
                            </WarningsContextProvider>
                        </AdminEmailsContextProvider>
                    </OrganizationContextProvider>
                </AuditingLogsContextProvider>
            </StatisticsContextProvider>
        </UsersAccessContextProvider>
    ) : children
}